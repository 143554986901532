export const USER_PRIVILEGE = {
  CLIENT: '0',
  SUPPLIER: '10',
  ADMIN: '100',
  EXECUTIVE: '200',
  PROJECT_MANAGER: '300',
} as const;

export const USER_PRIVILEGE_TRANSLATE = {
  '0': 'Cliente',
  '10': 'Proveedor',
  '100': 'Administrador',
  '200': 'Ejecutivo de ventas',
  '300': 'Project Manager',
} as const;

export const NOTIFICATION_TYPE = {
  INITIAL: 0,
  '1': 1,
  '2': 2,
  '3': 3,
} as const;

export const eventTypes = [
  'Aniversario',
  'Bar Mitzvah',
  'Bautizo',
  'Boda',
  'Civil',
  'Concierto',
  'Conferencias',
  'Cumpleaños',
  'Deportivo',
  'Desfile De Moda',
  'Despedida',
  'Fin De Año',
  'Graduación',
  'Lanzamiento',
  'Corporativo',
  'Primera Comunión',
  'Quince Años',
  'Otro',
] as const;

export const saleChannels = [
  'Página Web',
  'Referencias',
  'Ventas',
  'Redes Sociales',
] as const;

export const cancellations = [
  'Precio',
  'Tiempo',
  'Propuesta',
  'Actividad Cancelada',
  'Rechazado',
  'Postpuesto',
  'Sin Respuesta',
] as const;

export const clientTypes = [
  'Social',
  'Corporativo',
  'Intermediario',
  'Institución',
] as const;

export const phases = [
  { value: '1', text: '1. Registro del Cuestionario' },
  { value: '2', text: '2. Solicitud proveedores' },
  { value: '3', text: '3. Análisis del Proyecto' },
  { value: '4', text: '4. Creación de Cotización' },
  { value: '5', text: '5. Envío de la Cotización' },
  { value: '6', text: '6. Espera de Aprobación' },
  { value: '7', text: '7. Aprobado / Negociación' },
  { value: '8', text: '8. Análisis del proyecto' },
  { value: '9', text: '9. Registro de Proyecto' },
  { value: '10', text: '10. Producción del proyecto' },
  { value: '11', text: '11. Cierre / Facturación' },
  { value: '12', text: '12. Proyecto por cobrar' },
  { value: '13', text: '13. Proyecto finalizado' },
  { value: '14', text: '14. Cancelado' },
] as const;

export const PHASE_TOOLTIP = {
  '1': 'Registro del Cuestionario',
  '2': 'Solicitud proveedores',
  '3': 'Análisis del Proyecto',
  '4': 'Creación de Cotización',
  '5': 'Envío de la Cotización',
  '6': 'Espera de Aprobación',
  '7': 'Aprobado / Negociación',
  '8': 'Análisis del proyecto',
  '9': 'Registro de Proyecto',
  '10': 'Producción del proyecto',
  '11': 'Cierre / Facturación',
  '12': 'Proyecto por cobrar',
  '13': 'Proyecto finalizado',
  '14': 'Cancelado',
} as const;

export const STATUS_TEXT = {
  '0': 'Tentativo',
  '1': 'Aprobado',
  '2': 'Ejecutado',
  '3': 'Finalizado',
  '4': 'Cancelado',
} as const;

export const EMAIL_TEMPLATES_NAMES = {
  CONTACT_FORM_CLIENT: 'form-submit',
  WORK_WITH_US_CLIENT: 'work-with-us',
  DIGITAL_REQUEST_BLOOP_CLIENT: 'digital-request-client',
  DIGITAL_REQUEST_BLOOPEX_CLIENT: 'digital-request-bloopex-client',
  STEP_2_SUPPLIERS: 'send-summary',
  NEW_USER_DATA: 'new-user',
  DIGITAL_REQUEST_BLOOP: 'digital-request-to-bloop',
  CONTACT_DATA: 'client-contact-data',
  WORK_WITH_US_DATA: 'work-with-us-data',
} as const;
