import React from 'react';
import firebase from '../lib/firebase/client';

export default function useCollection<T = any>(path: string) {
  if (!path) {
    throw new Error('[hook]: useCollection need a param `path`');
  }
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<T[] | null>(null);
  const [error, setError] = React.useState<Error>(null);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(path)
      .onSnapshot(
        (snapShot) => {
          setLoading(true);
          const docs = snapShot.docs.map((doc) => (doc.data() as unknown) as T);
          setData(docs);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [path]);
  return { data, loading, error };
}
